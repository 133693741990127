export const Admin = "admin"
export const Login = "login"
export const ModifyUsers = "modify_users"
export const ModifyUsersRouting = "modify_users_routing"
export const ModifyPositions = "modify_positions"
export const ModifyPositionsActions = "modify_positions_actions"
export const ViewPositions = "view_positions"
export const ViewClient = "view_client"
export const ViewNewOperatorUI = "view_new_operator_ui"
export const ViewKnowledgeBaseSearchPanel = "view_knowledgebase_search_panel"
export const ViewNewKnowledgeBase = "view_new_knowledge_base"
export const ViewOldKnowledgeBase = "view_old_knowledgebase"
export const ModifyKnowledgeBase = "modify_knowledgebase"
export const ModifyKnowledgeBaseSubscriptions = "modify_knowledgebase_subscriptions"
export const ModifyIntentRouting = "modify_intent_routing"
export const PublishIntent = "publish_intent"
export const ViewDeleteIntentMarkupButtons = "view_delete_intent_markup_buttons"
export const ViewDeleteIntentFromDatabaseButton = "view_delete_intent_from_database_button"
export const ModifyClients = "modify_clients"
export const ViewUsers = "view_users"
export const ViewStat = "view_stat"
export const ViewDialogsSearch = "view_dialogs_search"
export const ModifyExtId = "modify_ext_id"
export const ViewHelpdesk = "view_helpdesk"
export const ModifyConfiguration = "modify_configuration"
export const ViewMonitoring = "view_monitoring"
export const ViewSwitchToOperatorButton = "view_switch_to_operator_button"
export const ViewSwitchToCurrentOperatorButton = "view_switch_to_current_operator_button"
export const ViewDeepLinking = "view_deeplinking"
export const ModifyUserDissatisfaction = "modify_user_dissatisfaction"
export const ViewRerouteButton = "view_reroute_btn"
export const SendFiles = "send_files"
export const SendPics = "send_pics"
export const ModifyCustomersConfigs = "modify_customers_configs"
export const ModifyCustomerSettings = "modify_customer_settings"
export const ViewSelectCustomersButton = "view_select_customers_button"
export const AllowDialogSuspend = "allow_dialog_suspend"
export const AllowDialogFinish = "allow_dialog_finish"
export const MoveDialogToQueueTop = "move_dialog_to_queue_top"
export const ControlClassifiers = "control_classifiers"
export const ViewActionsLog = "view_actions"
export const ViewModifyClassifierButtons = "view_modify_classifier_buttons"
export const ModifyOperatorStatus = "modify_operator_status"
export const ModifyDialogTopic = "modify_dialog_topic"
export const StartDialog = "start_dialog"
export const Cobrowsing = "cobrowsing"
export const AllowDialogSwitchToBot = "allow_dialog_switch_to_bot"
export const EnableHold = "enable_hold"
export const Supervisor = "supervisor"
export const ViewReports = "view_reports"
export const ViewQueues = "view_queues"
export const ModifyQueues = "modify_queues"
export const InviteUsersToProject = "invite_users_to_project"
export const ViewAgents = "view_agents"
export const ModifyAgents = "modify_agents"
export const ViewChannels = "view_channels"
export const ModifyChannels = "modify_channels"
export const ModifyOperatorParallelTasks = "modify_operator_parallel_tasks"
export const ModifyOperatorQueues = "modify_operator_queues"
export const ViewBotDialogs = "view_bot_dialogs"
export const ViewClientsSection = "view_clients_section"
export const ViewMassMailing = "view_mass_sending"
export const ModifyMassMailing = "modify_mass_sending"
export const AccessAllLinkedClients = "access_all_linked_clients"
